<template>
  <b-row>
    <b-col cols="2" class="no-padding">
      <meteociel-wrf place="gourette"></meteociel-wrf>
    </b-col>
    <b-col cols="2" class="no-padding">
      <meteociel-arome place="gourette"></meteociel-arome>
    </b-col>
    <b-col cols="4" class="no-padding">
      <meteoblue-multimodel place="gourette"></meteoblue-multimodel>
    </b-col>
    <b-col cols="4" class="no-padding">
      <base-64-image place="aspe-ossau" type="meteofrance-bra"></base-64-image>
    </b-col>
  </b-row>
</template>

<script>
import Base64Image from '@/components/Base64Image.vue';
import MeteocielWrf from '@/components/MeteocielWRF.vue';
import MeteocielArome from '@/components/MeteocielArome.vue';
import MeteoblueMultimodel from '@/components/MeteoblueMultimodel.vue';

export default {
  name: 'Gourette',
  components: {
    Base64Image,
    MeteocielWrf,
    MeteocielArome,
    MeteoblueMultimodel,
  },
};
</script>
